<template>
  <div class="container">
    <div class="title">浙影通</div>
    <div class="type-box">
      <div
          @click="onTypeTap('1')"
          :class="userType === 1 ? 'active-bottom' : ''"
      >
        个人用户
      </div>
      <div
          @click="onTypeTap('2')"
          :class="userType == 2 ? 'active-bottom' : ''"
      >
        法人用户
      </div>
    </div>
    <van-form @submit="onSubmit">
      <van-field
          v-model="username"
          name="用户名"
          label="用户名"
          placeholder="用户名"
          :rules="[{ required: true, message: '请填写用户名' }]"
      />
      <van-field
          v-model="password"
          type="password"
          name="密码"
          label="密码"
          placeholder="密码"
          :rules="[{ required: true, message: '请填写密码' }]"
      />
      <div style="margin: 16px">
        <van-button round block type="info" native-type="submit"
        >登录
        </van-button
        >
      </div>
    </van-form>

    <div v-isDev>
      <van-dialog v-model="show" title="点击号码复制"  theme="default" style="color: #2c72f7">
        <div style="color: #2c72f7;padding: 1rem">
          <p @click="cp('18888888881')">个人 18888888881 </p>
          <p @click="cp('13252057903')">放映队 13252057903 </p>
          <p @click="cp('18888888882')">集体代表人 18888888882 </p>
        </div>
      </van-dialog>
    </div>
  </div>
</template>

<script>
// import {SetPvAndUv} from '@/mixins/PvAndUv'
import {api} from '@/api/index'
import {Toast} from "vant";
import common from '@/utils/common'


export default {
  // mixins: [SetPvAndUv],
  components: {
  },
  data() {
    return {
      username: '',
      password: '',
      userType: '1',
      userId: '',
      page: 'home',
      show:true
    }
  },
  mounted() {
    // 判断环境
    console.log('环境', common.getContainerEnv())
    // common.getContainerEnv()
    // this.time = new Date().getFullYear()+'-'+(new Date().getMonth()+1)+'-'+new Date().getDate()
    console.log('首页进入' + window.location.href)
    // TODO 删掉手机号 个人 18888888881

    // 放映队 13252057903

    // 集体代表人 18888888882
    // this.username =
    //   this.getQuery('username') || config.isDev ? '13252057903' : ''
    this.username =
        this.getQuery('username')
    this.password = this.getQuery('password')
    this.userType = this.getQuery('usertype') ? this.getQuery('usertype') : '1'
    this.page = this.getQuery('page') ? this.getQuery('page') : 'home'
    if (this.username) {
      this.onSubmit(111)
    }
  },
  methods: {
    cp(num){
      // 拷贝号码，并关闭窗口
      console.log('点击测试', num)
      this.copyText(num)
      Toast('号码已复制')
    },
    copyText(text) {
      const input = document.createElement('input');
      input.value = text;
      document.body.appendChild(input);
      input.select();
      document.execCommand('copy');
      document.body.removeChild(input);
      this.show = false
    },
    //获取地址上的参数
    getQuery(name) {
      var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
      var r = window.location.search.substr(1).match(reg)
      if (r != null) return unescape(r[2])
      return null
    },
    onSubmit(values) {
      console.log('submit', values)
      api
          .login({
            username: this.username,
            userType: this.userType,
            password: '123456',
          })
          .then((res) => {
            this.userId = res.userid
            this.$store.commit('changeToken', res.token)
            this.$store.commit('changeZlbUserId', res.userid)
            this.$store.commit('changeUserType', res.userType)
            this.$store.commit('changeUserName', res.username)
            this.$store.commit('changeUserInfo', res)
            console.log('用户类型', this.$store.state.userType)
            ZWJSBridge.setStorageSync({key: 'userInfo', value: res})
                .then(() => {
                  console.log('存储成功')
                })
                .catch((err) => {
                  console.log('存储失败', err)
                })
            api
                .addUniqueVisitor({
                  dataSource: 1,
                  requestSource: 1,
                })
                .then((res) => {
                })
            this.$router.push({
              path: '/' + this.page,
            })
          })

      // this.$axios({
      //   apiUrl: "/test/login",
      //   url: "",
      //   method: "GET",
      //   data: {
      //     username: this.username,
      //     userType: this.userType,
      //     password: "123456",
      //   },
      // }).then((res) => {
      //   console.log("token成功" + JSON.stringify(res));
      //   this.userId = res.result.userid;
      //   this.$store.commit("changeToken", res.result.token);
      //   this.$store.commit("changeZlbUserId", res.result.userid);
      //   this.$store.commit("changeUserType", res.result.userType);
      //   console.log("用户类型", this.$store.state.userType);
      //   this.$router.push({
      //     path: "/" + this.page,
      //   });
      // });
    },
    //类型切换
    onTypeTap(type) {
      this.userType = type
    },
  },
}
</script>

<style scoped lang="less">
.type-box {
  display: flex;
  justify-content: space-around;
  padding: 0 50px;
  margin-bottom: 20px;
}

.type-box div {
  padding: 5px 0;
}

.active-bottom {
  border-bottom: 3px solid #2c72f7;
}

.title {
  margin-top: 40px;
  font-size: 35px;
  text-align: center;
  margin-bottom: 20px;
  color: @color-primary;
}

.container {
}
</style>
